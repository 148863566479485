
import Vue from "vue"
import axios, { AxiosError, AxiosResponse } from "axios"
import { EventBus } from "@/plugins/eventBus"

interface Permission {
  name: string,
  value: string,
  disabled: boolean
}

interface Body {
  firstname: string,
  lastname: string,
  email: string,
  phone: string,
  password?: string,
  api_access?: string,
  spaces: Array<number>,
  api_roles: Array<string>,
  administrator: boolean
}

interface Space {
  id: number,
  name: string
}

export default Vue.extend({
  name: 'UserForm',
  data: () => ({
    route: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    api_access: false,
    api_access_value: "",
    password: "",
    show: false,
    spaces: [] as Array<Space>,
    selectedSpaces: [],
    selectedPermissions: [],
    isAdmin: false,
    search: null,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }),
  computed: {    
    emailRule() {
      return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
    },
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    nameLengthRule() {
      return (v: string) => ((v.length > 1 && v.length < 100) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    stringLengthRule() {
      return (v: string) => ((v.length > 1 && v.length < 254) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    phoneNumberRule() {
      return (v: string) => (!v || (5 <= v.length && v.length <= 20)) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    atLeastOneRequired() {
      return (v: Array<number>) => ((v.length > 0) || !v) || this.$vuetify.lang.t('$vuetify.required_field')
    },
    availablePermissions() {
      const permissionNames = Object.keys(this.$vuetify.lang.locales.en.permissions_names).filter((permission) => {
        return permission
      })
      let permissions: Array<Permission> = []

      permissionNames.forEach(permission => {
        permissions.push({
          "name": this.$vuetify.lang.t('$vuetify.permissions_names.' + permission),
          "value": permission,
          "disabled": permission === 'app-admin' || permission === 'space-users'
        })
      })

      return permissions
    }
  },
  mounted() {
    if (this.$route.name === "AdminUserCreate") {
      this.route = "admin"
    } else if (this.$route.name === "SpaceUserCreate") {
      this.route = "space"
    }
    if (this.route !== "") this.getSpaces()
  },
  watch: {
    search(val) {
      this.getSpaces(val)
    },
  },
  methods: {
    createUser() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        let body: Body = {
            firstname: this.first_name,
            lastname: this.last_name,
            email: this.email,
            phone: this.phone,
            spaces: this.selectedSpaces,
            api_roles: this.selectedPermissions,
            administrator: this.isAdmin
        }
        if (this.api_access) {
          body.api_access = this.api_access_value
        } else {
          body.password = this.password
        }
        axios
          .post(`/v1/${this.route}/users`, body)
          .then(() => {
            EventBus.$emit('listParagonDataReload')
            EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.user_successfully_created')})
            this.$router.push(`/${this.route}/users`)
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
      }
    },
    getSpaces(search = null) {
      let url = this.route === "admin" ? "/v1/admin/spaces" : "/v1/agencies"
      url += search !== null ? `?page=1&name=${search}` : ""
      axios
        .get(url)
        .then((response: AxiosResponse) => {
          response.data.forEach((space: Space)=>{
            this.spaces.push(space)
          })
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    },
    cancel() {
      this.$router.push(`/${this.route}/users`)
    }
  }
})
